<template>
  <div class="cooperation">
    <beacon-banner
      :bannerSrc="banner_2"
      title="寻找志同道合的伙伴  共创食品安全未来"
      subTitle="Looking  for  partners"
    ></beacon-banner>
    <div class="title">加盟合作流程</div>
    <div class="content">
      <div class="step_list">
        <div class="left">
          <div class="step_item">
            <div class="img_wrap">
              <span class="step_index">1</span>
              <img
                class="step_img"
                :src="join_egg"
                alt=""
              />
            </div>
            <span class="step_text">提交申请</span>
          </div>
          <div class="line"></div>
          <div class="step_item">
            <div class="img_wrap">
              <span class="step_index">2</span>
              <img
                class="step_img"
                :src="join_egg"
                alt=""
              />
            </div>
            <span class="step_text">沟通审核</span>
          </div>
          <div class="line"></div>
          <div class="step_item">
            <div class="img_wrap">
              <span class="step_index">3</span>
              <img
                class="step_img"
                :src="join_egg"
                alt=""
              />
            </div>
            <span class="step_text">签订协议</span>
          </div>
          <div class="line center"></div>
        </div>
        <div class="right">
          <div class="line center"></div>
          <div class="step_item">
            <div class="img_wrap">
              <span class="step_index">4</span>
              <img
                class="step_img"
                :src="join_egg"
                alt=""
              />
            </div>
            <span class="step_text">打款充值</span>
          </div>
          <div class="line"></div>
          <div class="step_item">
            <div class="img_wrap">
              <span class="step_index">5</span>
              <img
                class="step_img"
                :src="join_egg"
                alt=""
              />
            </div>
            <span class="step_text">代理培训</span>
          </div>
          <div class="line"></div>
          <div class="step_item">
            <div class="img_wrap">
              <span class="step_index">6</span>
              <img
                class="step_img"
                :src="join_egg"
                alt=""
              />
            </div>
            <span class="step_text">市场启动</span>
          </div>
        </div>
      </div>
    </div>
    <div class="bg_green">
      <div class="form_wrap">
        <div class="form_title">申请成为代理商</div>
        <div class="sub_title">请认真填写如下信息，提交后专人与您联系</div>
        <div class="form">
          <div class="form_group">
            <input type="text" placeholder="企业名称" />
          </div>
          <div class="form_group">
            <input type="text" placeholder="联系人" />
          </div>
          <div class="form_group">
            <input type="text" placeholder="电话" />
          </div>
          <div class="form_group">
            <button @click="submit">确认提交</button>
          </div>
        </div>
      </div>
      <img class="dots_map" :src="join_dotsmap" alt="" />
    </div>
  </div>
</template>

<script>
import { banner_2, join_egg, join_dotsmap } from "@/utils/images";
export default {
  data() {
    return {
      banner_2,
      join_egg,
      join_dotsmap
    }
  },
  methods: {
    submit() {
      alert("这个功能暂时还没有呢，请查看底部的联系方式联系我们哦~")
    }
  }
};
</script>

<style lang="scss" scoped>
.step_list {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: calc(26px + 32px);
  margin-top: -84px;
  .left,
  .right {
    display: flex;
    width: 50%;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 1200px) {
      width: 100%;
    }
    .line {
      width: 11.8%;
      height: 2px;
      background: #efefef;
      margin-top: 84px;
      &.center {
        width: 5.9%;
        @media screen and (max-width: 1200px) {
          display: none;
        }
      }
    }
  }
  .step_item {
    margin-top: 84px;
    max-width: 85px;
    min-width: 70px;
    width: 12.7%;
    position: relative;
    .img_wrap {
      position: relative;
      .step_index {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 38px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #999999;
      }
      .step_img {
        max-width: 100%;
      }
    }
    .step_text {
      text-align: center;
      width: 100%;
      top: 100%;
      margin-top: 26px;
      font-size: 19px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 32px;
      color: #333333;
      position: absolute;
      white-space: nowrap;
    }
  }
}

.bg_green {
  background-color: #4cb034;
  overflow: hidden;
  display: flex;
  align-items: center;
  .form_wrap {
    float: left;
    width: 44.5%;
    padding-left: 15.6%;
    @media screen and (max-width: 1200px) {
      width: 100%;
      padding-right: 15.6%;
      padding-bottom: 5%;
      padding-top: 5%;
    }
    .form_title {
      font-size: 2.5rem;
      line-height: 4.25rem;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
    .sub_title {
      font-size: 1rem;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 1.71rem;
      color: #ffffff;
      margin-top: 1.25rem;
    }
    .form_group {
      margin-top: 3.55%;
      button {
        padding: 1.25rem 1.67rem;
        font-size: 1.25rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #4cb034;
        background: #ffffff;
        border-radius: 1rem;
        outline: none;
        border: none;
        cursor: pointer;
      }
      input {
        width: 100%;
        padding: 1.25rem;
        border: none;
        outline: none;
        border-radius: 1rem;
        font-size: 1.25rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        &::placeholder {
          color: #999999;
        }
      }
      &:last-child {
        margin-top: 6.95%;
      }
    }
    .form {
      margin-top: 6.95%;
    }
  }
  .dots_map {
    float: left;
    width: 55.5%;
    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
}

@media screen and (max-width: 992px) {
  .title {
    padding-bottom: 30vpx;
  }
  .content {
    padding-bottom: 47vpx;
  }
  .step_list {
    padding: 0 12vpx;
    .left,
    .right {
      width: 50%;
      .line {
        height: 1px;
        &.center {
          display: block;
        }
      }
    }
    .step_item {
      min-width: 0;
      width: 22vpx;
      height: 26vpx;
      .img_wrap {
        .step_index {
          font-size: 10vpx;
        }
      }
      .step_text {
        margin-left: -10vpx;
        margin-top: 7vpx;
        font-size: 10vpx;
      }
    }
  }
  .bg_green {
    position: relative;
    .form_wrap {
      padding: 0 38vpx 30vpx 38vpx;
      position: relative;
      z-index: 1;
      .form_title {
        margin-top: 30vpx;
        line-height: normal;
        font-size: 24vpx;
      }
      .sub_title {
        margin-top: 10vpx;
        line-height: normal;
        font-size: 12vpx;
      }
      .form_group {
        margin-top: 15vpx;
        button {
          padding: 14vpx;
          width: 100%;
          border-radius: 4vpx;
          font-size: 14vpx;
        }
        input {
          padding: 14vpx;
          border-radius: 4vpx;
          font-size: 14vpx;
        }
        &:last-child {
          margin-top: 30vpx;
        }
      }
      .form {
        margin-top: 30vpx;
      }
    }
    .dots_map {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 0;
    }
  }
}
</style>